<template>
  <app-chat v-if="quoteStatus !== shipmentQuoteStatuses.WAITING_PRIMARY_USER_APPROVAL || messages.totalEntries > 0"
            :messages="messages?.data"
            :badge="messages.unread"
            :me="me"
            :disabled="quoteStatus === shipmentQuoteStatuses.REJECTED || disabled"
            :isOpen="visible"
            :sendAttachment="isSendingAttachment"
            :open="open"
            :placeholder="$t('views.chat.placeholder')"
            :on-load="load"
            @onSubmit="submit"
            :close="close"
            :quote-Id="quoteId" />
</template>

<script>
import {
  computed, defineComponent, ref, toRefs, watch
} from 'vue';
import {useStore} from 'vuex';
import {theme} from '@/config/theme/themeVariables';
import {shipmentQuoteStatuses} from '@/constants';
import VueTypes from 'vue-types';

export default defineComponent({
  name: 'ClientAgentChat',
  props: {
    quoteId: VueTypes.string.required,
    quoteStatus: VueTypes.string.required,
    disabled: VueTypes.bool.def(false)
  },
  setup(props) {
    const {
      getters, dispatch, commit
    } = useStore();

    const visible = ref(false);
    const isSendingAttachment = ref(false);
    const messages = computed(() => getters.messages);
    const me = ref(getters.me);


    const {quoteId} = toRefs(props);
    watch(quoteId, (value) => {
      if (value) {
        dispatch('getUnreadMessagesCount', value);
      }
    }, {immediate: true});

    const load = async ({loaded}) => {
      dispatch('getMessages', props.quoteId).then(() => {
        return loaded(messages.value.totalPages - messages.value.page);
      });
    };

    const submit = async (data) => {
      isSendingAttachment.value = data.file != null;
      await dispatch('sendMessage', {id: props.quoteId, data: data});
      isSendingAttachment.value = false;
    };

    const open = () => {
      commit('setIsOpen', true);
      visible.value = true;
    };

    const close = () => {
      commit('setIsOpen', false);
      visible.value = false;
    };

    return {
      theme,
      visible,
      isSendingAttachment,
      messages,
      me,
      load,
      submit,
      open,
      close,
      shipmentQuoteStatuses
    };
  }
});
</script>
