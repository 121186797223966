<template>
  <file-list-wrapper>
    <sdCards :headless="title ? false : true" :title="title">
      <div class="file-list">
        <template v-if="files.length > 0">
          <div  v-for="file in files" :key="file" class="file-list__single d-flex">
            <div class="file-single-info d-flex">
              <div class="file-single__content">
                <span class="file-name">{{file.filename}}</span>
                <span class="file-content-action">
                  <a to="#" @click="download(file.filename, file.url)">
                    {{$t('actions.download')}}
                  </a>
                  <a v-if="canDelete" to="#" @click="deleteFile(file)">
                    {{$t('actions.delete')}}
                  </a>
                </span>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <a-empty />
        </template>
      </div>
      <template #button>
        <slot name="buttons"></slot>
      </template>
    </sdCards>
  </file-list-wrapper>
</template>

<script>
import {defineComponent} from 'vue';
import {FileListWrapper} from './style';
import VueTypes from 'vue-types';
import {downloadFile} from '@/helpers/downloadFile';

export default defineComponent({
  name: 'FileListCard',
  components: {FileListWrapper},
  props: {
    files: VueTypes.array.def([]),
    title: VueTypes.string.def(''),
    canDelete: VueTypes.bool.def(false)
  },
  emits: ['deleteFile'],
  setup(_, context) {
    const download = (filename, url) => downloadFile(filename, url);
    const deleteFile = (file) => context.emit('deleteFile', file);

    return {
      download,
      deleteFile
    };
  }
});
</script>

<style lang="scss" scoped>
.ant-card {
  height: 100% !important;
}
</style>
