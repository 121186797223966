<template>
  <DetailsWrapper>
    <div class="quote-summary">
      <sdCards v-if="shipment && shipmentRequest" :headless="headless"
               :title="headless ? undefined : $t('views.shipmentRequest.shipmentDetails')">
        <a-row class="about-project mt-0 mb-0" :gutter="gutter">
          <template v-for="(field, index) in visibleFields" :key="index">
            <a-col v-if="field.key !== 'deliveryAddressLineOne'"
                  :xs="colSpans.xs" :sm="colSpans.sm" :md="colSpans.md" :lg="colSpans.lg"
                  :xl="colSpans.xl" :xxl="colSpans.xxl">
              <span>{{$t(`models.${field.accessor}.attributes.${field.key}`)}}</span>
              <p v-if="field.key === 'deliveryCity'">
                {{getCityByCode(data[field.accessor]['destinationCountry'], data[field.accessor]['deliveryCity'])}} </p>
              <p v-else-if="field.key !== 'quoteDeadline'" class="text-break">{{data[field.accessor][field.key]}}</p>
              <p v-else class="text-break">
                {{shipment.quoteDeadline ? moment(shipment.quoteDeadline).format('DD MMM YYYY') : ''}}
              </p>
            </a-col>
            <a-col v-else :span="20">
              <span>{{$t('models.shipment.attributes.deliveryAddressLineOne')}}</span>
              <p class="text-break">{{shipment['deliveryAddressLineOne'] || '--'}}</p>
            </a-col>
          </template>
          <a-col
            v-if="view !== shipmentDetailsView.SUMMARY && shipment.temperatureControl"
            :xs="colSpans.xs" :sm="colSpans.sm" :md="colSpans.md" :lg="colSpans.lg"
            :xl="colSpans.xl" :xxl="colSpans.xxl">
            <span>{{$t('models.shipment.attributes.temperatureControl')}}</span>
            <p class="text-break">
              {{shipment.temperatureControlValue + ' ' +
              $t(`models.shipment.attributes.temperatureUnits.${shipment.temperatureControlUnit}`)}}</p>
          </a-col>
          <a-col v-if="rejectionReason" :span="20">
            <span>{{$t('models.shipmentQuote.attributes.rejectionReason')}}</span>
            <p class="text-break">{{rejectionReason}}</p>
          </a-col>
        </a-row>
      </sdCards>
    </div>
  </DetailsWrapper>
</template>

<script>
import {
  defineComponent, toRefs, computed
} from 'vue';
import VueTypes from 'vue-types';
import {DetailsWrapper} from '@/components/shared/styledComponents/DetailsWrapper';
import {shipmentDetailsView} from '@/constants';
import moment from 'moment';
import {getCityByCode} from '@/helpers/citiesOptions';

export default defineComponent({
  name: 'ShipmentCard',
  emits: ['requestDocuments'],
  components: {DetailsWrapper},
  props: {
    shipment: VueTypes.object.def({}),
    shipmentRequest: VueTypes.object.def({}),
    canRequestDocuments: VueTypes.bool.def(false),
    headless: VueTypes.bool.def(false),
    view: VueTypes.string.def(shipmentDetailsView.SUMMARY),
    rejectionReason: VueTypes.string.def(undefined)
  },
  setup(props) {
    const {view} = toRefs(props);
    const colSpans = computed(() => {
      if (view.value === shipmentDetailsView.FULL) {
        return {
          xs: 24, sm: 12, md: 9, lg: 6, xl: 5, xxl: 4
        };
      } else {
        return {
          xs: 24
        };
      }
    });

    const gutter = computed(() => view.value === shipmentDetailsView.FULL ? 12 : 0);


    const fields = computed(() => [
      {accessor: 'shipmentRequest', key: 'identifier'},
      {accessor: 'shipmentRequest', key: 'shippingFromName'},
      {accessor: 'shipmentRequest', key: 'shipmentType'},
      {accessor: 'shipmentRequest', key: 'shippingMethod'},
      {accessor: 'shipmentRequest', key: 'scope'},
      {accessor: 'shipment', key: 'identifier'},
      {
        accessor: 'shipment', key: 'landingPort', hidden: props.view === shipmentDetailsView.SUMMARY
      },
      {accessor: 'shipment', key: 'destinationCountryName'},
      {
        accessor: 'shipment', key: 'deliveryCity', hidden: props.view === shipmentDetailsView.SUMMARY
      },
      {
        accessor: 'shipment', key: 'deliveryAddressLineOne', hidden: props.view === shipmentDetailsView.SUMMARY
      },
      {
        accessor: 'shipment', key: 'packagingType', hidden: props.view === shipmentDetailsView.SUMMARY
      },
      {
        accessor: 'shipment', key: 'packagingQuantity', hidden: props.view === shipmentDetailsView.SUMMARY
      },
      {accessor: 'shipment', key: 'quoteDeadline'},
      {
        accessor: 'shipment', key: 'currency', hidden: props.view === shipmentDetailsView.SUMMARY
      },
      {
        accessor: 'shipment', key: 'totalValue', hidden: props.view === shipmentDetailsView.SUMMARY
      },
      {
        accessor: 'shipment', key: 'cifValue', hidden: props.view === shipmentDetailsView.SUMMARY
      },
      {
        accessor: 'shipment', key: 'endUserName', hidden: props.view === shipmentDetailsView.SUMMARY
      }
    ]);

    const visibleFields = computed(() => fields.value.filter((field) => !field.hidden));


    const data = computed(() => {
      return {shipment: props.shipment, shipmentRequest: props.shipmentRequest};
    });

    return {
      data,
      visibleFields,
      shipmentDetailsView,
      gutter,
      colSpans,
      moment,
      getCityByCode
    };
  }
});
</script>


<style lang="scss" scoped>
.hutSpt {
  height: 94%;
}

.quote-summary, .quote-summary .ant-card {
  height: 100%;
}

.text-break {
  word-break: break-word!important;
  overflow-wrap: break-word!important;
}
</style>

